<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >
      <v-card>

          <v-toolbar
              dark
              color="brown"
              class="color-gradient-1"
              dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ lang==='id'? itemModified.description : itemModified.descriptionEn }}</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-toolbar-items >

            </v-toolbar-items>
          </v-toolbar>
        <v-card-title>
        </v-card-title>
        <v-card-subtitle>
        </v-card-subtitle>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="computedDataItems"
              hide-default-header
              hide-default-footer
              dense
          ></v-data-table>
        </v-card-text>
      </v-card>


    </v-dialog>
  </div>

</template>

<script>

import FileService from "../../services/apiservices/file-service";
import FInfrastructureService from "@/services/apiservices/f-infrastructure-service";

export default {
  name: 'InfrastructureDetailDialog',
  components: { },
  props:{
    formMode: String,
  },
  data() {
    return {
      title: '',
      snackBarMesage: '',
      snackbar: false,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemModified: '',
      itemsFDivision: [],
      selectedItems: '',

      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Judul', value: 'title', width:'25%' },
        { text: 'Nilai', value: 'value', width:'50%' },
      ],

    }
  },
  computed: {
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    computedDataItems(){
      let items =[]

      // console.log(JSON.stringify(this.itemModified))

      let finfrastructureTypeDesc = this.lookupFInfratructureType(this.itemModified.finfrastructureTypeBean).description
      if (finfrastructureTypeDesc == undefined) {
        finfrastructureTypeDesc =""
      }

      if(this.lang==='id'){
          let alamat = `${this.lookupFSubArea(this.itemModified.fsubAreaBean).description} - ${this.lookupFArea(this.itemModified.fareaBean).description}`
          items.push({ title: 'Alamat',  value: alamat })

          if (this.itemModified.tahun > 0) {
            items.push({title: 'Data Tahun', value: this.itemModified.tahun})
          }
          if (this.itemModified.phone != '' && this.itemModified.phone != undefined) {
            items.push({title: 'Phone', value: this.itemModified.phone})
          }
          if (this.itemModified.email != ''  && this.itemModified.email != undefined) {
            items.push({title: 'Email', value: this.itemModified.email})
          }

          if (this.itemModified.remark1 != ''  && this.itemModified.remark1 != undefined){
            items.push({ title: 'Jenis',  value: this.itemModified.remark1 })
          }

          // console.log(JSON.stringify(this.itemModified))

        if (finfrastructureTypeDesc.trim().toLowerCase().includes("pelabuhan")){

          if (this.itemModified.remark3 != ''  && this.itemModified.remark3 != undefined) {
            items.push({title: 'Fasilitas', value: this.itemModified.remark3})
          }
          if (this.itemModified.remark4 != '' && this.itemModified.remark4 != undefined) {
            items.push({title: 'Aset yang bisa dikerjasamakan', value: this.itemModified.remark4})
          }
          if (this.itemModified.remark5 != '' && this.itemModified.remark5 != undefined) {
            items.push({title: 'Tonase (Maks)', value: this.itemModified.remark5})
          }
          if (this.itemModified.remark6 != '' && this.itemModified.remark6 != undefined) {
            items.push({title: 'Ukuran Kapal (Maks)', value: this.itemModified.remark6})
          }
          if (this.itemModified.remark7 != '' && this.itemModified.remark7 != undefined) {
            items.push({title: 'Harga Sewa Aset', value: this.itemModified.remark7})
          }
          if (this.itemModified.remark8 != '' && this.itemModified.remark8 != undefined) {
            items.push({title: 'Sumber Data', value: this.itemModified.remark8})
          }

          if (this.itemModified.remarkInt1 != 0 && this.itemModified.remarkInt1 != undefined) {
            items.push({title: 'Jml Dermaga', value: this.itemModified.remarkInt1})
          }

          if (this.itemModified.remarkInt1 != 0.0 && this.itemModified.remarkInt1 != undefined) {
            items.push({title: 'Kedalaman', value: `${this.itemModified.remarkInt2} meter`})
          }
          if (this.itemModified.remarkDouble2 != 0.0 && this.itemModified.remarkDouble2 != undefined) {
            items.push({title: 'Tonase/DWT', value: this.itemModified.remarkDouble2})
          }
        }
      }
      if(this.lang === 'en'){

        let alamat = `${this.lookupFSubArea(this.itemModified.fsubAreaBean).description} - ${this.lookupFArea(this.itemModified.fareaBean).description}`
        items.push({ title: 'Address',  value: alamat })

        if (this.itemModified.tahun > 0) {
          items.push({title: 'Data For The Year', value: this.itemModified.tahun})
        }
        if (this.itemModified.phone != '' && this.itemModified.phone != undefined) {
          items.push({title: 'Phone', value: this.itemModified.phone})
        }
        if (this.itemModified.email != ''  && this.itemModified.email != undefined) {
          items.push({title: 'Email', value: this.itemModified.email})
        }

        if (this.itemModified.remark1 != ''  && this.itemModified.remark1 != undefined){
          items.push({ title: 'Type',  value: this.itemModified.remark1 })
        }

        // console.log(JSON.stringify(this.itemModified))

      if (finfrastructureTypeDesc.trim().toLowerCase().includes("pelabuhan")){

        if (this.itemModified.remark3 != ''  && this.itemModified.remark3 != undefined) {
          items.push({title: 'Facilities', value: this.itemModified.remark3})
        }
        if (this.itemModified.remark4 != '' && this.itemModified.remark4 != undefined) {
          items.push({title: 'Assets that can be collaborated', value: this.itemModified.remark4})
        }
        if (this.itemModified.remark5 != '' && this.itemModified.remark5 != undefined) {
          items.push({title: 'Tonnage (Maks)', value: this.itemModified.remark5})
        }
        if (this.itemModified.remark6 != '' && this.itemModified.remark6 != undefined) {
          items.push({title: 'Ship Size (Maks)', value: this.itemModified.remark6})
        }
        if (this.itemModified.remark7 != '' && this.itemModified.remark7 != undefined) {
          items.push({title: 'Asset Rental Fee', value: this.itemModified.remark7})
        }
        if (this.itemModified.remark8 != '' && this.itemModified.remark8 != undefined) {
          items.push({title: 'Data Source', value: this.itemModified.remark8})
        }

        if (this.itemModified.remarkInt1 != 0 && this.itemModified.remarkInt1 != undefined) {
          items.push({title: 'Number of docks', value: this.itemModified.remarkInt1})
        }

        if (this.itemModified.remarkInt1 != 0.0 && this.itemModified.remarkInt1 != undefined) {
          items.push({title: 'Depth', value: `${this.itemModified.remarkInt2} meter`})
        }
        if (this.itemModified.remarkDouble2 != 0.0 && this.itemModified.remarkDouble2 != undefined) {
          items.push({title: 'Tonnage/DWT', value: this.itemModified.remarkDouble2})
        }
      }
    }
    return items
    }

  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
  },

  methods: {

    showDialog(item, itemsFDivision) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow
      // this.itemModified = item
      this.itemModified = this.initializeEditMode(item)

      this.itemsFDivision = itemsFDivision


    },
    setDialogState(value){
      this.dialogShow =value
    },
    closeForm(){
      this.dialogShow = false
    },

    initializeEditMode(item){
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = ''

      FInfrastructureService.getFInfrastructureById(item.id).then(
          response =>{
            // console.log(JSON.stringify(response.data))

            this.itemDefault = Object.assign({}, response.data)
            this.itemModified = response.data
          },
          error =>{
            console.log(error)
          }
      )
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },
    lookupFInfratructureType (finfratructureTypeBean) {
      const str = this.$store.state.potensi.itemsFInfrastructureType.filter(x => x.id===finfratructureTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },
    lookupFSubArea (fsubAreaBean) {
      const str = this.$store.state.potensi.itemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFArea (fareaBean) {
      const str = this.$store.state.potensi.itemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

    lookupImageMarkerUrl(markerImage){
      if (markerImage===undefined || markerImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        // console.log(FileService.file_url(markerImage))
        return FileService.image_url_ori(markerImage)
      }
    },


  }

}
</script>

<style scoped>
.v-data-table.row-height-50 td {
  height: 10px !important;
}
</style>