export default class FInfratructure {
  constructor(
    id,
    kode1,
    description = "",
    fdivisionBean = 0,
    finfrastructureTypeBean = 0,
    statusActive =true,
    baseId,
    lang,

    fsubAreaBean=0,
    fareaBean=0,

    notes ="",
    lat=0.0,
    lon=0.0,
    luas=0,
    website="",
    fax="",
    phone="",
    email="",
    tahun=0,
    address ="",
    district="",
    city="",
    state="",

    remark1 = "",
    remark2 = "",
    remark3 = "",
    remark4 = "",
    remark5 = "",
    remark6 = "",
    remark7 = "",
    remark8 = "",


    remarkInt1 = 0,
    remarkInt2 = 0,

    remarkDouble1 = 0.0,
    remarkDouble2 = 0.0,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;
    this.baseId = baseId;
    this.lang = lang;

    this.finfrastructureTypeBean = finfrastructureTypeBean;

    this.fsubAreaBean = fsubAreaBean;
    this.fareaBean = fareaBean;

    this.notes = notes;
    this.lat = lat;
    this.lon = lon;
    this.luas = luas;
    this.website = website;
    this.fax = fax;
    this.phone = phone;
    this.email = email;
    this.tahun = tahun;
    this.address = address;
    this.district = district;
    this.city = city;
    this.state = state;

    this.remark1 = remark1;
    this.remark2 = remark2;
    this.remark3 = remark3;
    this.remark4 = remark4;
    this.remark5 = remark5;
    this.remark6 = remark6;
    this.remark7 = remark7;
    this.remark8 = remark8;

    this.remarkInt1 = remarkInt1;
    this.remarkInt2 = remarkInt2;

    this.remarkDouble1 = remarkDouble1;
    this.remarkDouble2 = remarkDouble2;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
