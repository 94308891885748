<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialogShow = false">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title>Grafik Pengunjung</v-toolbar-title>
      </v-toolbar>
      <ChartVisitorDialogContent></ChartVisitorDialogContent>
    </v-dialog>
  </div>
</template>

<script>
import ChartVisitorDialogContent from "@/components/public-chart/ChartVisitorDialogContent.vue";

export default {
  name: "ChartVisitorDialog",
  components: { ChartVisitorDialogContent },
  props: {},
  data() {
    return {
      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    closeForm() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        this.$refs.refCloseConfirmDialog.showDialog(
          " Sudah terdapat modifikasi data",
          "Tetap tutup dan reset perubahan?"
        );
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>